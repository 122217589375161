<template>
  <div class="catalog-container">
    <CatalogBanner/>
    <div class="mt-5 text-center" v-if="isLoading">
      <font-awesome icon="spinner" size="8x" spin />
    </div>
    <div class="row mx-0 mt-2" v-else>
      <div class="catalog-breadcrumb col mb-2">
        <router-link :to="`/product-catalog/${$route.params.slug}`">Home</router-link>
        <i class="icon-next"></i>
        <span>{{ convertSlug2($route.params.slug2) }}</span>
      </div>
      <div class="col-lg-3 col-6 mb-2 align-self-center">
        <router-link :to="`/product-catalog/${$route.params.slug}`">
          <b-button class="btn-share-catalog bg-gold float-right">
            <fawesome-pro variant="far" icon="arrow-left" class="pr-1" /> Back to Collection
          </b-button>
        </router-link>
        <!-- <b-button variant="success" class="btn-share-catalog float-right" v-b-modal.modal-share-link>
          Share <div class="svg-icon svg-share-icon ml-2"></div>
        </b-button> -->
      </div>
      <div class="col-12 text-center mt-lg-3" v-if="products.length == 0">
        <img src="@/assets/img/shoping-bag-empty.png" alt="" />
        <h4>Catalog is empty</h4>
      </div>
      <div class="product-catalog-list col-12" v-else>
        <div class="product-catalog-item product-info p-0 m-0" v-for="product in products" :key="product.id" @click="selectedProduct = product" v-b-modal.modal-product-catalog>
          <div class="position-relative mb-1">
            <b-img-lazy 
              :alt="setProductImgAltText(product)"
              :title="product.name"
              :src="product.product_images[0].medium_image_url"
              @error.native="replaceByDefaultImage(product)"
              class="product-catalog-img"
              center
            />
            <b-img :src="product.active_promo_badge_url" alt="" class="promo-badge-img" v-if="product.active_promo_badge_url" />
          </div>
          <div class="product-catalog-brand">{{ product.brand_name }}</div>
          <div class="product-catalog-name">{{ product.name }}</div>
          <div class="product-catalog-price">
            <b class="d-block" :class="{'text-gold' : (product.product_type == 4 && product.discount_active_bundle) || product.discount_active || product.bundle_dynamic_price }">{{ product.bundle_dynamic_price ? $helpers.product.formatBundlePrice(product) : $helpers.format.price(product.price_display) }}</b>
            <span v-if="product.discount_badge" class="badge badge-beige mr-1">{{ product.discount_badge }}</span>
            <strike v-if="product.product_type == 4 && product.discount_active_bundle">{{ $helpers.product.formatBundlePriceOrigin(product) }}</strike>
            <strike v-else-if="product.discount_active">{{ $helpers.format.price(product.price) }}</strike>
          </div>
          <div class="product-badge mt-1" v-if="product.badge_list && product.badge_list.length || product.greatest_voucher_discount">
            <span class="product-voucher-card-badge" v-if="product.greatest_voucher_discount">{{ `Disc. ${product.greatest_voucher_discount.discount_type == 1 ? $helpers.format.compactPrice(product.greatest_voucher_discount.discount) : product.greatest_voucher_discount.discount + '%' }` }}</span>
            <template v-for="(badge, i) in product.badge_list">
              <span v-if="badge.badge_text" :key="i" :style="{
                'color': badge.badge_fontcolor,
                'background': badge.badge_bgcolor,
              }">{{ badge.badge_text }}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3" v-if="totalProduct > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalProduct"
          :per-page="perPage"
          align="right"
          @change="pageChange"
          class="mb-0"
        >
        </b-pagination>
      </div>
    </div>
    <ModalProductCatalog :product="selectedProduct" :resellerContact="resellerContact" @showModalCategorySize="categorySize = $event" :isLoading="isLoadingProductDetail" />
    <!-- <ModalShareLink title="Share Product Catalog" :link="shareableLink" /> -->
    <ModalCategorySize :product="categorySize" />
    <CatalogFooter @setResellerContact="resellerContact = $event" />
  </div>
</template>
<script>
const CatalogBanner = () => import("@/components/resellers/catalog/CatalogBanner");
const CatalogFooter = () => import("@/components/resellers/catalog/CatalogFooter");
const ModalProductCatalog = () => import("@/components/modals/ModalProductCatalog");
// const ModalShareLink = () => import("@/components/modals/ModalShareLink");
const ModalCategorySize = () => import("@/components/modals/ModalCategorySize");

export default {
  name: "ProductCatalogForCustomer",
  components: {
    CatalogBanner,
    CatalogFooter,
    ModalProductCatalog,
    // ModalShareLink,
    ModalCategorySize,
  },
  data() {
    return {
      products: [],
      selectedProduct: null,
      shareableLink: process.env.VUE_APP_FE_URL + this.$router.currentRoute.path.substring(1),
      categorySize: null,
      resellerContact: {},
      isLoading: true,

      isLoadingProductDetail: false,

      // For pagination
      currentPage: 1,
      totalProduct: 0,
      perPage: 8,
    }
  },
  computed: {
    productId() {
      return this.$route.params?.productId?.split("-").pop();
    }
  },
  watch: {
    "$route.query": {
      handler() {
        this.currentPage = this.$route.query.p || 1;
        this.getProductByCatalogGroupId();
      },
      immediate: true,
    },
  },
  methods: {
    async getProductByCatalogGroupId() {
      try {
        this.isLoading = true;
        const groupId = this.$route.params.slug2.split("-").pop();
        const res = await this.$api.catalog.getProductByCatalogGroupId(groupId, this.currentPage - 1, this.perPage);
        if (res.status === 200) {
          this.products = res.data.data.map(product => {
            if (product.product_type == 4 && product.product_bundle?.length) {
              product.product_bundle.map(el => {
                el.bundle_product.map(rel => {
                  rel.selectedVariant = null
                  rel.selectedColor = null
                  rel.selected = false
                  return rel
                })
                el.submitted = null
              })
            }
            return product;
          });

          this.totalProduct = res.data.meta.total;

          // Open modal prroduct catalogif slug productId exist
          if(this.productId) this.viewDetailProduct(); 
        }
        else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoading = false
      } catch (error) {
        console.error(error);
        this.isLoading = false
      }
    },
    viewDetailProduct() {
      this.$bvModal.show("modal-product-catalog");
      const productFound = this.products.find(el => el.id == this.productId);
      if (productFound) this.selectedProduct = productFound;
      else this.getProductDetail();
    },
    async getProductDetail() {
      try {
        this.isLoadingProductDetail = true;
        const res = await this.$api.product.getProductDetail(this.productId);
        if (res.status === 200) this.setProduct(res.data.data);
        else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoadingProductDetail = false;
      } catch (error) {
        this.isLoadingProductDetail = false;
        console.error(error);
      }
    },
    setProduct(product) {
      if (product.product_type == 4 && product.product_bundle?.length) {
        product.product_bundle.map(el => {
          el.bundle_product.map(rel => {
            rel.selectedVariant = null
            rel.selectedColor = null
            rel.selected = false
            return rel
          })
          el.submitted = null
        })
      }
      this.selectedProduct = product;
    },
    pageChange(page) {
      // window.scrollTo(0, 0);
      this.$router.push({
        query: Object.assign({}, this.$route.query, { p: page }),
      });
    },
    replaceByDefaultImage(product) {
      product.product_images[0].medium_image_url = require("@/assets/img/default-image.png");
    },
    convertSlug2(slug) {
      let groupName = slug.split("-").map(el => el.charAt(0).toUpperCase() + el.substring(1).toLowerCase());
      groupName = Number.isNaN(parseInt(groupName[groupName.length - 1])) ? groupName.join(" ") : groupName.slice(0, -1).join(" ")
      return groupName;
    },
    setProductImgAltText(product) {
      return `${product.brand_name} ${product.name}${product.kadar > 0 ? ` with purity of ${product.kadar}` : ''}`
    },
  },
}
</script>